import React from 'react';
import { graphql, PageProps } from 'gatsby';
import styled from 'styled-components';
import { breakpoint } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import AboutPressPost from 'components/organisms/know-how-post';
import KnowHowSlider, { OtherPostsType } from 'components/organisms/know-how-slider';
import PostHeader, { PostHeaderType } from 'components/organisms/post-header';
import { PostTemplateType } from 'components/organisms/post-renderer';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';
import Breadcrumb from 'components/atoms/breadcrumb';

type PostDataType = {
  pressPostData: {
    postTemplate: PostTemplateType[];
    otherPosts?: OtherPostsType;
    readMinutes?: string;
    socialsShareLabel?: string;
    showBanner?: null | boolean;
  };
};

type DataProps = {
  aboutPressPost: PostHeaderType & PostDataType;
  commonComponents: {
    commonComponentsData: {
      successPartBanner: LetsTalkBannerType;
    };
  };
};

const StyledBreadcrumbWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscaleGray0};
  padding: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.md};
  }

  .breadcrumb {
    max-width: ${({ theme }) => theme.wrapper};
    margin-inline: auto;

    &__list {
      &__item {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;

        a {
          color: ${({ theme }) => theme.colors.gray100};
        }

        a[aria-current='page'] {
          color: ${({ theme }) => theme.colors.gray60};
        }
      }
    }

    &__separator {
      color: ${({ theme }) => theme.colors.gray80};
    }
  }
`;

export const AboutPressPostPage = ({
  pageContext,
  data,
}: PageProps<DataProps, PageContextType>) => {
  const {
    aboutPressPost: {
      pressPostData: { postTemplate, otherPosts, readMinutes, socialsShareLabel, showBanner },
      author,
      categories,
      date,
      featuredImage,
      title,
      tags,
    },
    commonComponents: {
      commonComponentsData: { successPartBanner },
    },
  } = data;

  return (
    <Layout {...{ pageContext }}>
      <StyledBreadcrumbWrapper>
        <Breadcrumb {...{ pageContext }} />
      </StyledBreadcrumbWrapper>
      <article>
        <PostHeader {...{ author, categories, date, featuredImage, title, tags, readMinutes }} />
        <AboutPressPost {...{ postTemplate, socialsShareLabel }} />
      </article>
      {showBanner ? <LetsTalkBanner {...successPartBanner} /> : null}
      {otherPosts?.postsList ? (
        <KnowHowSlider variant={showBanner ? 'post-with-banner' : 'post'} {...otherPosts} />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query AboutPressPost($id: String, $commonComponentsSlug: String!) {
    aboutPressPost: wpPress(id: { eq: $id }) {
      title
      pressPostData {
        readMinutes
        socialsShareLabel
        postTemplate {
          ... on WpPress_Presspostdata_PostTemplate_Text {
            content
            fieldGroupName
          }
          ... on WpPress_Presspostdata_PostTemplate_Stats {
            fieldGroupName
            statsList {
              description
              fieldGroupName
              title
            }
          }
          ... on WpPress_Presspostdata_PostTemplate_ReportForm {
            emailPlaceholder
            fieldGroupName
            formConsent
            text
            heading
            button {
              label
              ariaLabel
              icon {
                name
              }
            }
          }
          ... on WpPress_Presspostdata_PostTemplate_Youtube {
            fieldGroupName
            youtubeLink
          }
          ... on WpPress_Presspostdata_PostTemplate_Instagram {
            fieldGroupName
            instagramLink
          }
          ... on WpPress_Presspostdata_PostTemplate_Image {
            fieldGroupName
            image {
              ...WpImageFragment
            }
          }
          ... on WpPress_Presspostdata_PostTemplate_Cite {
            cite
            fieldGroupName
          }
          ... on WpPress_Presspostdata_PostTemplate_Author {
            additionalLabel
            fieldGroupName
            firstName
            heading
            lastName
            position
            avatar {
              ...WpImageFragment
            }
          }
        }
        otherPosts {
          heading
          subHeading
          linkButton {
            icon {
              name
            }
            label
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
          postButton {
            label
            ariaLabel
          }

          postsList {
            ... on WpPress {
              id
              featuredImage {
                node {
                  ...WpImageFragment
                }
              }
              date(formatString: "DD.MM.YYYY")
              tags {
                nodes {
                  name
                  uri
                }
              }
              title
              uri
              slug
            }
          }
        }
      }
      author {
        node {
          lastName
          firstName
          description
          avatar {
            url
          }
        }
      }
      date(locale: "PL", formatString: "DD.MM.YYYY")
      featuredImage {
        node {
          ...WpImageFragment
        }
      }
      tags {
        nodes {
          name
          uri
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        successPartBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutPressPostPage;
